@import "_generated-font-mixins.scss";
.profile {
  &__background {
    position: relative;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    height: 100px;

    & img {
      object-fit: cover;
    }
  }

  &__avatar {
    position: relative;

    box-sizing: border-box;
    width: 100%;
    height: 43px;
    height: auto;
    margin-bottom: 15px;
    padding-top: 20px;

    & img {
      position: relative;
      top: auto;
      left: auto;

      overflow: hidden;
      display: block;

      box-sizing: border-box;
      width: 86px;
      height: 86px;
      margin: 0 auto;

      border: 3px solid #fff;
      border-radius: 50%;
    }
  }

  &__login {
    display: flex;
    gap: 5px;
    justify-content: center;
    width: 100%;

    & span {
      overflow: hidden;

      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      text-overflow: ellipsis;
    }

    &__verified {
      color: #65922c;
    }
  }

  &__verified__icon {
    margin-top: -3px;

    &::after {
      @include icon-verified-user;

      font-size: 17px;
      color: #65922c;
    }
  }

  &__text {
    margin-top: 6px;

    font-size: 13px;
    line-height: 16px;
    color: #999;
    text-align: center;
  }

  &__button__wrapper {
    margin-bottom: 11px;
    padding: 20px 10px 10px;
  }
}

.rating {
  margin-top: 10px;

  &__block {
    position: relative;

    display: flex;
    justify-content: center;

    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;

    font-size: 13px;
    line-height: 16px;
    color: #999;

    &__icon {
      width: 14px;
      height: 14px;
      margin-top: -3px;
      margin-right: 3px;

      &::after {
        @include icon-star-fill;

        font-size: 14px;
        color: #ffaf27;
      }
    }

    &__text {
      margin-right: 5px;
      font-size: 17px;
      line-height: 17px;
      color: #f5b049;
    }

    &__counter {
      margin-top: 1px;
      font-size: 13px;
      line-height: 14px;
      color: #315dbb;
    }
  }
}

.with__background {
  margin-top: -43px;
  padding-top: 0;
}

.icon__message {
  width: 20px;
  height: 20px;

  &::after {
    @include icon-message;

    font-size: 19px;
    color: #b5b8bc;
  }
}
