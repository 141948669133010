@import "_generated-font-mixins.scss";
.profile__wrapper {
  overflow: scroll;
  height: calc(100vh - 100px);
}

.content__wrapper {
  margin-top: 10px;
  padding-bottom: 52px;
}

.content__data {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 70px 10px 0;

  font-size: 17px;
  line-height: 20px;
  color: #999;
  text-align: center;
}

.contacts {
  &__link {
    position: relative;

    display: flex;

    height: 59px;
    padding: 20px 20px 20px 28px;

    font-size: 15px;
    color: #3f74ad;
    text-decoration: none;

    border-bottom: 1px solid #e4e4e4;
  }

  &__icon {
    margin-top: -5px;
    margin-right: 15px;

    &::before {
      @include icon-mobile;

      font-size: 20px;
      color: #5e9cec;
    }
  }
}

.icon__active {
  font-size: 12px;
}

.ads__loading {
  opacity: 0.5;
}

.ads__margin {
  margin: 0 10px;
}
